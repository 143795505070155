
















import { Component, Vue } from 'vue-property-decorator'

import RegisterServiceUserForm from '@/partials/forms/Services/RegisterServiceUserForm.vue'

@Component({
  components: {
    RegisterServiceUserForm
  }
})
export default class SelfRegisterForVoucher extends Vue {
  person: ServicePerson | EventPerson = 'subscriber'
}
